import { Breadcrumb, BreadcrumbProps } from "../Breadcrumb";

const breadcrumbItems: BreadcrumbProps["items"] = [
  { path: process.env.NEXT_PUBLIC_FRONT_BASE_URL, title: "HOME" },
  { path: "/", title: "商品ラインナップ" },
  { path: "/cart", title: "ショッピングカート" },
  { path: "/checkout", title: "ご注文フォーム" },
  { path: "/confirm", title: "ご注文確認" },
];

const getBreadcrumbItems = (inputPath: string) => {
  const index = breadcrumbItems.findIndex((breadcrumb) => breadcrumb.path === inputPath);

  if (index === -1) {
    throw new Error("Invalid path");
  }

  const breadcrumbList = breadcrumbItems.slice(0, index); // inputPathに至るまでのパンクズリスト
  const currentTitle = breadcrumbItems[index].title; // 最後のタイトル

  return {
    items: breadcrumbList,
    currentTitle,
  };
};

export interface CheckoutBreadcrumbProps {
  currentPath: "/cart" | "/checkout" | "/confirm";
}

export function CheckoutBreadcrumb({ currentPath }: CheckoutBreadcrumbProps) {
  const { items, currentTitle } = getBreadcrumbItems(currentPath);

  return <Breadcrumb items={items} current={currentTitle} />;
}
